import 'vant/es/dialog/style';
import useClipboard from "vue-clipboard3";
import { Dialog } from 'vant';
import HeaderView from "../components/HeaderView.vue";
import { getmyteam, referral, newreferral, delreferral } from "../common/api";
export default {
  name: 'TeamView',
  components: {
    HeaderView,
    'vdialog': Dialog.Component
  },

  data() {
    return {
      teamList: {
        count: 0,
        list: [],
        page: 1,
        limit: 10,
        loading: false,
        finished: false,
        error: false,
        commission: 0,
        teamcount: 0,
        team_recharge: 0,
        bonus_ratio: 0
      },
      codeList: {
        count: 0,
        list: [],
        loading: false,
        finished: false,
        error: false
      },
      refreshing: false,
      page: "",
      ratio: "",
      show: false,
      parent: null
    };
  },

  created() {
    this.$store.dispatch("asyncupdatecwinfo");
    this.page = this.$route.params.type;
  },

  methods: {
    copy(referral_code) {
      try {
        const {
          toClipboard
        } = useClipboard();
        let text = "https://ineedpay.com" + "/register/" + referral_code;
        toClipboard(text);
        this.$toast.success("Copy success");
      } catch (e) {
        this.$toast.fail("Copy fail");
      }
    },

    delcode(id) {
      delreferral({
        id: id
      }).then(() => {
        this.$toast.success("Delete success");
        this.onRefresh();
      }).catch(err => {
        this.$toast.fail(err);
      });
    },

    childteam(phone) {
      this.parent = phone;
      this.onLoad();
    },

    onLoad() {
      if (this.page === "team") {
        if (this.teamList.finished) {
          this.teamList.page = 1;
          this.teamList.list = [];
          this.refreshing = false;
          this.teamList.finished = false;
        }

        getmyteam({
          page: this.teamList.page,
          limit: this.teamList.limit,
          parent: this.parent
        }).then(res => {
          if (this.teamList.page > 1 && res.data.children.length < 10) {
            this.teamList.loading = false;
            this.teamList.finished = true;
            this.refreshing = false;
          } else {
            this.teamList.commission = res.data.commission;
            this.teamList.team_recharge = res.data.team_recharge;
            this.teamList.teamcount = res.data.teamcount;
            this.teamList.bonus_ratio = res.data.bonus_ratio;
            this.teamList.list = this.teamList.list.concat(res.data.children);
            this.teamList.count = res.data.count;
            this.teamList.loading = false;
            this.teamList.finished = this.teamList.list.length >= this.teamList.count;
            this.teamList.page++;
          }
        }).catch(() => {
          this.teamList.loading = false;
          this.teamList.error = true;
        });
      } else if (this.page === "invitation") {
        if (this.codeList.finished) {
          this.codeList.list = [];
          this.refreshing = false;
          this.codeList.finished = false;
        }

        referral().then(res => {
          this.codeList.list = res.data;
          this.codeList.loading = false;
          this.codeList.finished = true;
          this.refreshing = false;
        }).catch(() => {
          this.codeList.finished = false;
          this.codeList.error = true;
        });
      }
    },

    onRefresh() {
      if (this.page === "team") {
        this.teamList.loading = true;
        this.teamList.finished = true;
        this.onLoad();
      } else if (this.page === "invitation") {
        this.codeList.finished = true;
        this.codeList.loading = true;
        this.onLoad();
      } // // 清空列表数据
      // this.finished = false;
      // // 重新加载数据
      // // 将 loading 设置为 true，表示处于加载状态
      // this.loading = true;
      // this.onLoad();

    },

    gridonclick(type) {
      if (type == "team") {
        this.parent = null;
        this.onLoad();
      }

      this.page = this.$route.params.type;
    },

    onAdd() {
      this.show = true;
    },

    // addnewteam() {
    //   addnewteam().then(res => {
    //   }).catch(() => {
    //   });
    // },
    confirmSubmit(action) {
      if (action === "confirm") {
        if (this.ratio === "" || Number(this.ratio) <= 0 || Number(this.ratio) > Number(this.$store.state.cwinfo.bonus_ratio)) {
          this.$toast("Team member bonus ratio is incorrect");
          return false;
        }

        newreferral({
          bonus_ratio: this.ratio
        }).then(() => {
          this.$toast("Success");
          this.show = false;
          this.onRefresh();
        }).catch(() => {
          this.$toast("error");
          this.show = true;
        });
      } else {
        return true;
      }
    }

  }
};
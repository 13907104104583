import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue";
const _hoisted_1 = {
  key: 1,
  class: "heard-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");

  const _component_van_image = _resolveComponent("van-image");

  const _component_van_nav_bar = _resolveComponent("van-nav-bar");

  return _openBlock(), _createBlock(_component_van_nav_bar, {
    class: "navbar",
    onClickLeft: _cache[0] || (_cache[0] = $event => $setup.onClickLeft($props.routepath))
  }, _createSlots({
    title: _withCtx(() => [$props.title === '' ? (_openBlock(), _createBlock(_component_van_image, {
      key: 0,
      height: "2rem",
      width: "8.25rem",
      src: "/assets/logoback.png"
    })) : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($props.title), 1))]),
    right: _withCtx(() => [_createVNode(_component_van_image, {
      width: "2.8rem",
      height: "2.8rem",
      round: "",
      src: "/assets/icon/logoHome.png"
    })]),
    _: 2
  }, [$props.showleft ? {
    name: "left",
    fn: _withCtx(() => [_createVNode(_component_van_icon, {
      name: "arrow-left"
    })]),
    key: "0"
  } : undefined]), 1024);
}
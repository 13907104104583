import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  style: {
    "color": "#02A758"
  }
};
const _hoisted_2 = {
  key: 1,
  style: {
    "color": "#ff976a"
  }
};
const _hoisted_3 = {
  key: 2,
  style: {
    "color": "#ee0a24"
  }
};
const _hoisted_4 = {
  class: "van-cell__value"
};
const _hoisted_5 = {
  key: 0,
  style: {
    "color": "#02A758"
  }
};
const _hoisted_6 = {
  key: 1,
  style: {
    "color": "#ff976a"
  }
};
const _hoisted_7 = {
  key: 2,
  style: {
    "color": "#ee0a24"
  }
};
const _hoisted_8 = {
  class: "van-cell__label"
};
const _hoisted_9 = {
  class: "van-cell__value"
};
const _hoisted_10 = {
  key: 0,
  style: {
    "color": "#ED6A5B"
  }
};
const _hoisted_11 = {
  key: 1,
  style: {
    "color": "#02A758"
  }
};
const _hoisted_12 = {
  key: 2,
  style: {
    "color": "#02A758"
  }
};
const _hoisted_13 = {
  key: 3,
  style: {
    "color": "#02A758"
  }
};
const _hoisted_14 = {
  key: 4,
  style: {
    "color": "#ff976a"
  }
};
const _hoisted_15 = {
  key: 5,
  style: {
    "color": "#ee0a24"
  }
};
const _hoisted_16 = {
  class: "van-cell__label"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_HeaderView = _resolveComponent("HeaderView");

  const _component_van_grid_item = _resolveComponent("van-grid-item");

  const _component_van_grid = _resolveComponent("van-grid");

  const _component_van_cell = _resolveComponent("van-cell");

  const _component_van_icon = _resolveComponent("van-icon");

  const _component_van_list = _resolveComponent("van-list");

  const _component_van_field = _resolveComponent("van-field");

  const _component_van_picker = _resolveComponent("van-picker");

  const _component_van_popup = _resolveComponent("van-popup");

  const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_HeaderView, {
    title: "Historys",
    routepath: "/"
  }), _createVNode(_component_van_grid, {
    clickable: "",
    class: "btngrid",
    border: false,
    "column-num": 2,
    gutter: "20"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_grid_item, {
      text: "Order",
      icon: "friends-o",
      onClick: _cache[0] || (_cache[0] = $event => $options.gridonclick('order'))
    }), _createVNode(_component_van_grid_item, {
      text: "Details",
      icon: "peer-pay",
      onClick: _cache[1] || (_cache[1] = $event => $options.gridonclick('details'))
    })]),
    _: 1
  }), _createVNode(_component_van_pull_refresh, {
    modelValue: $data.refreshing,
    "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => $data.refreshing = $event),
    "pulling-text": "Pull down to refresh...",
    "loosing-text": "Release to refresh...",
    "loading-text": "loading",
    onRefresh: $options.onRefresh
  }, {
    default: _withCtx(() => [$data.page === 'order' ? (_openBlock(), _createBlock(_component_van_list, {
      key: 0,
      loading: $data.orderList.loading,
      "onUpdate:loading": _cache[2] || (_cache[2] = $event => $data.orderList.loading = $event),
      error: $data.orderList.error,
      "onUpdate:error": _cache[3] || (_cache[3] = $event => $data.orderList.error = $event),
      "error-text": "The request failed, click Reload",
      "loading-text": "loading",
      finished: $data.orderList.finished,
      "finished-text": "No more data",
      class: "menulist",
      onLoad: $options.onLoad
    }, {
      default: _withCtx(() => [_createVNode(_component_van_cell, {
        border: false,
        class: "menuheard"
      }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.orderList.list, item => {
        return _openBlock(), _createBlock(_component_van_cell, {
          key: item,
          center: "",
          title: item.orderno
        }, {
          label: _withCtx(() => [item.quota_status === 'receive' ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(item.quota_status), 1)) : item.quota_status === 'return' ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(item.quota_status), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(item.quota_status), 1))]),
          icon: _withCtx(() => [item.quota_status === 'receive' ? (_openBlock(), _createBlock(_component_van_icon, {
            key: 0,
            style: {
              "color": "#02A758"
            },
            name: "success"
          })) : item.quota_status === 'return' ? (_openBlock(), _createBlock(_component_van_icon, {
            key: 1,
            style: {
              "color": "#ff976a"
            },
            name: "cross"
          })) : (_openBlock(), _createBlock(_component_van_icon, {
            key: 2,
            style: {
              "color": "#ee0a24"
            },
            name: "exchange"
          }))]),
          value: _withCtx(() => [_createElementVNode("div", _hoisted_4, [item.quota_status === 'receive' ? (_openBlock(), _createElementBlock("span", _hoisted_5, "₹ " + _toDisplayString(item.amount), 1)) : item.quota_status === 'return' ? (_openBlock(), _createElementBlock("span", _hoisted_6, "₹ " + _toDisplayString(item.amount), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_7, "₹ " + _toDisplayString(item.amount), 1)), _createElementVNode("div", _hoisted_8, _toDisplayString(item.ctime), 1)])]),
          _: 2
        }, 1032, ["title"]);
      }), 128))]),
      _: 1
    }, 8, ["loading", "error", "finished", "onLoad"])) : _createCommentVNode("", true), $data.page === 'details' ? (_openBlock(), _createBlock(_component_van_list, {
      key: 1,
      loading: $data.detailsList.loading,
      "onUpdate:loading": _cache[8] || (_cache[8] = $event => $data.detailsList.loading = $event),
      error: $data.detailsList.error,
      "onUpdate:error": _cache[9] || (_cache[9] = $event => $data.detailsList.error = $event),
      "error-text": "The request failed, click Reload",
      "loading-text": "loading",
      finished: $data.detailsList.finished,
      "finished-text": "No more data",
      class: "menulist",
      onLoad: $options.onLoad
    }, {
      default: _withCtx(() => [_createVNode(_component_van_cell, {
        border: false,
        class: "menuheard"
      }), _createVNode(_component_van_field, {
        modelValue: $data.type,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $data.type = $event),
        "is-link": "",
        readonly: "",
        name: "picker",
        label: "Type",
        placeholder: "Click to select Type",
        onClick: _cache[5] || (_cache[5] = $event => $data.showPicker = true)
      }, null, 8, ["modelValue"]), _createVNode(_component_van_popup, {
        show: $data.showPicker,
        "onUpdate:show": _cache[7] || (_cache[7] = $event => $data.showPicker = $event),
        position: "bottom"
      }, {
        default: _withCtx(() => [_createVNode(_component_van_picker, {
          columns: $data.columns,
          onConfirm: $options.onConfirm,
          onCancel: _cache[6] || (_cache[6] = $event => $data.showPicker = false)
        }, null, 8, ["columns", "onConfirm"])]),
        _: 1
      }, 8, ["show"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.detailsList.list, item => {
        return _openBlock(), _createBlock(_component_van_cell, {
          key: item,
          center: "",
          title: item.type
        }, {
          label: _withCtx(() => [_createElementVNode("span", null, _toDisplayString($options.formatorderno(item)), 1)]),
          icon: _withCtx(() => [item.type == 'less' ? (_openBlock(), _createBlock(_component_van_icon, {
            key: 0,
            style: {
              "color": "#ED6A5B"
            },
            name: "minus"
          })) : item.type == 'add' || item.type == 'recharge' || item.type == 'commission' ? (_openBlock(), _createBlock(_component_van_icon, {
            key: 1,
            style: {
              "color": "#02A758"
            },
            name: "plus"
          })) : item.type == 'receive' ? (_openBlock(), _createBlock(_component_van_icon, {
            key: 2,
            style: {
              "color": "#02A758"
            },
            name: "success"
          })) : item.type == 'award' ? (_openBlock(), _createBlock(_component_van_icon, {
            key: 3,
            style: {
              "color": "#02A758"
            },
            name: "circle"
          })) : item.type == 'return' ? (_openBlock(), _createBlock(_component_van_icon, {
            key: 4,
            style: {
              "color": "#ff976a"
            },
            name: "cross"
          })) : (_openBlock(), _createBlock(_component_van_icon, {
            key: 5,
            style: {
              "color": "#ee0a24"
            },
            name: "exchange"
          }))]),
          value: _withCtx(() => [_createElementVNode("div", _hoisted_9, [item.type == 'less' ? (_openBlock(), _createElementBlock("span", _hoisted_10, "₹ " + _toDisplayString(item.amount), 1)) : item.type == 'add' || item.type == 'recharge' || item.type == 'commission' ? (_openBlock(), _createElementBlock("span", _hoisted_11, "₹ " + _toDisplayString(item.amount), 1)) : item.type == 'receive' ? (_openBlock(), _createElementBlock("span", _hoisted_12, "₹ " + _toDisplayString(item.amount), 1)) : item.type == 'award' ? (_openBlock(), _createElementBlock("span", _hoisted_13, "₹ " + _toDisplayString(item.amount), 1)) : item.type == 'return' ? (_openBlock(), _createElementBlock("span", _hoisted_14, "₹ " + _toDisplayString(item.amount), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_15, "₹ " + _toDisplayString(item.amount), 1)), _createElementVNode("div", _hoisted_16, _toDisplayString(item.ctime), 1)])]),
          _: 2
        }, 1032, ["title"]);
      }), 128))]),
      _: 1
    }, 8, ["loading", "error", "finished", "onLoad"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["modelValue", "onRefresh"])], 64);
}
import "core-js/modules/es.array.push.js";
import { Dialog } from 'vant';
import HeaderView from "../components/HeaderView.vue";
export default {
  name: 'HomeView',
  components: {
    HeaderView
  },

  created() {
    this.$store.dispatch('asyncupdatesiteconfig');
    this.$store.dispatch("asyncupdatecwinfo");
  },

  methods: {
    sing_out() {
      let that = this;
      Dialog.confirm({
        message: 'Logging out of the current account will suspend all functions. Are you sure you want to log out ?'
      }).then(() => {
        localStorage.removeItem("token");
        that.$router.push({
          "name": "login"
        });
      }).catch(() => {});
    },

    contact_service() {
      if (this.$store.state.siteconfig.customer_whatsapp_account) {
        window.location.href = "xamla:" + this.$store.state.siteconfig.customer_whatsapp_account;
      } else {
        window.location.href = "xamla:https://www.google.com";
      }
    }

  }
};
import axios from "./http";
let {get, post, deletea } = axios

// 获取网站配置
export const getsiteconfig = data => get("/cwapisys/siteconfig/", data)

// 登陆
export const login = data => post("/cwapi/login/", data)

// 注册
export const register = data => post("/cwapi/cwregister/", data)

// 获取用户信息
export const getcwinfo = data => get("/cwapi/collectionworkerinfo/", data)

// 获取我的团队成员
export const getmyteam = data => get("/cwapi/myteam/", data)

// 添加新团队成员
export const addnewteam = data => post("/cwapi/createsubordinateworkers/", data)

//邀请相关
export const referral = data => get("/cwapi/referral/ ", data)
export const newreferral = data => post("/cwapi/referral/ ", data)
export const delreferral = data => deletea("/cwapi/referral/ ", data)


// 获取paytm账号列表
export const getcollectiontoollist = data => get('/cwapi/collectiontool/', data)

// 新建paytm账号
export const addcollectiontool = data => post('/cwapi/collectiontool/', data)

//订单列表
export const getorderlists = data => get("/cwapi/orderlog/", data)

//资金记录
export const getassetsrecord = data => get("/cwapi/quotalog/", data)

// 获取文章(通过id)
export function getnewsIddetail(id) {
    return get("/news/detail/" + id + "/");
}

// 获取文章(通过code)
export function getnewsCodedetail(code) {
    return get("/news/code/" + code + "/");
}

// 获取新闻列表
export const getnewslist = data => get("/news/list/", data)

// 获取通知
export const getnotice = data => get("/news/notice/", data)

//USDT充值
export const rechargequota = data => post("/cwapi/rechargequota/", data)

//获取代付订单
export const getpayorder = data => get("/cwapi/payorder/", data)

//获取代付订单详情
export const getpayorderinfo = data => get("/cwapi/payorder/", data)

//抢单
export const downorder = data => post("/cwapi/payorder/", data)

//上传图片
export const uploadimage = data => post("/cwapi/upload_image/", data)

//取消订单
export const cancelorder = data => post("/cwapi/cancelpayorder/", data)


//获取自定义配置信息
export const getconfigs = data => get("/cwapisys/getconfigs/", data)
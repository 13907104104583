import "core-js/modules/es.array.push.js";
import { login } from "../common/api";
export default {
  name: 'LoginView',

  data() {
    return {
      phone: "",
      password: "",
      loading: false,
      rules: {
        phone: [{
          required: true,
          message: 'Please enter phone number'
        }, {
          pattern: /^(\+?91|0)?[0123456789]\d{9}$/,
          message: 'please enter a valid phone number'
        }],
        password: [{
          required: true,
          message: 'Please enter password'
        }, {
          pattern: /^.{6,}/,
          message: 'Please enter a password of at least 6 digits'
        }]
      }
    };
  },

  created() {
    this.$store.dispatch('asyncupdatesiteconfig');
  },

  methods: {
    onSubmit(formdata) {
      let that = this;
      that.loading = true;
      login(formdata).then(res => {
        if (res.code === 200) {
          that.$toast.success("successful login");
          localStorage.setItem("token", res.data.token);
          setTimeout(function () {
            that.$router.push({
              "name": "home"
            });
          }, 1000);
        } else {
          that.loading = false;
          that.$toast.fail(res.errmsg);
        }
      }).catch(error => {
        that.$toast.fail("Network Error: " + error.response.code);
        that.loading = false;
      });
    }

  }
};
import "core-js/modules/es.array.push.js";
import router from "../router/index.js";
export default {
  name: 'HeaderView',
  props: {
    showleft: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ""
    },
    routepath: {
      type: String,
      default: ""
    }
  },

  setup() {
    const onClickLeft = path => {
      if (path != "") {
        router.push(path);
      } else {
        history.back();
      }
    };

    return {
      onClickLeft
    };
  }

};
import HeaderView from "../components/HeaderView.vue";
import { getorderlists, getassetsrecord } from "../common/api";
export default {
  name: 'HistoryView',
  components: {
    HeaderView
  },

  data() {
    return {
      orderList: {
        count: 0,
        list: [],
        page: 1,
        limit: 10,
        loading: false,
        finished: false,
        error: false
      },
      detailsList: {
        count: 0,
        list: [],
        page: 1,
        limit: 10,
        loading: false,
        finished: false,
        error: false
      },
      page: "",
      refreshing: false,
      type: "all",
      showPicker: false,
      columns: ["all", "recharge", "award", "receive", "add", "less", "commission", "return"]
    };
  },

  created() {
    this.page = this.$route.params.type;
  },

  methods: {
    formatorderno(item) {
      if (item.remark) {
        return item.remark;
      } else {
        let s = String(item.ctime);
        return s.replace(" ", "").replace(/-/g, "").replace(/:/g, "") + item.id;
      }
    },

    onConfirm(value) {
      this.type = value;
      this.showPicker = false;
      this.onRefresh();
    },

    onLoad() {
      if (this.page === "order") {
        if (this.orderList.finished) {
          this.orderList.page = 1;
          this.orderList.list = [];
          this.refreshing = false;
          this.orderList.finished = false;
        }

        getorderlists({
          page: this.orderList.page,
          limit: this.orderList.limit
        }).then(res => {
          if (res.data.result.length < 1) {
            this.orderList.loading = false;
            this.orderList.finished = true;
            this.refreshing = false;
          } else {
            this.orderList.list = this.orderList.list.concat(res.data.result);
            this.orderList.count = res.data.count;
            this.orderList.loading = false;
            this.orderList.finished = this.orderList.list.length >= this.orderList.count;
            this.orderList.page++;
          }
        }).catch(() => {
          this.orderList.loading = false;
          this.orderList.error = true;
        });
      } else if (this.page === "details") {
        if (this.detailsList.finished) {
          this.detailsList.page = 1;
          this.detailsList.list = [];
          this.refreshing = false;
          this.detailsList.finished = false;
        }

        getassetsrecord({
          page: this.detailsList.page,
          limit: this.detailsList.limit,
          type: this.type
        }).then(res => {
          if (res.data.result.length < 1) {
            this.detailsList.loading = false;
            this.detailsList.finished = true;
            this.refreshing = false;
          } else {
            this.detailsList.list = this.detailsList.list.concat(res.data.result);
            this.detailsList.count = res.data.count;
            this.detailsList.loading = false;
            this.detailsList.finished = this.detailsList.list.length >= this.detailsList.count;
            this.detailsList.page++;
          }
        }).catch(() => {
          this.detailsList.loading = false;
          this.detailsList.error = true;
        });
      }
    },

    onRefresh() {
      if (this.page === "order") {
        this.orderList.loading = true;
        this.orderList.finished = true;
        this.onLoad();
      } else if (this.page === "details") {
        this.detailsList.finished = true;
        this.detailsList.loading = true;
        this.onLoad();
      }
    },

    gridonclick(page) {
      this.page = page;
    }

  }
};
import { Dialog } from 'vant';
import { register } from "../common/api";
export default {
  name: 'RegisterView',

  data() {
    return {
      loading: false,
      phone: "",
      password: "",
      password2: "",
      referral_code: "",
      rules: {
        phone: [{
          required: true,
          message: 'Please enter phone number'
        }, {
          pattern: /^(\+?91|0)?[0123456789]\d{9}$/,
          message: 'please enter a valid phone number'
        }],
        password: [{
          required: true,
          message: 'Please enter password'
        }, {
          pattern: /^.{6,}/,
          message: 'Please enter a password of at least 6 digits'
        }],
        code: []
      }
    };
  },

  created() {
    this.$store.dispatch('asyncupdatesiteconfig');
    this.referral_code = this.$router.currentRoute.value.params.referral_code;
  },

  methods: {
    confirmpassword(val) {
      return this.password === val;
    },

    onSubmit(formdata) {
      let that = this;
      that.loading = true;
      register(formdata).then(res => {
        that.loading = false;

        if (res.code === 200) {
          Dialog.confirm({
            message: 'Successful Registration',
            cancelButtonText: "Download App",
            cancelButtonColor: "#1989fa"
          }).then(() => {
            window.location.href = that.$store.state.siteconfig.app_download_url;
          }).catch(() => {
            window.location.href = that.$store.state.siteconfig.app_download_url;
          });
        } else {
          that.$toast.fail(res.errmsg);
        }
      }).catch(error => {
        that.$toast.fail("Network Error: " + error.response.code);
        that.loading = false;
      });
    }

  }
};
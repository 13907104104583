import axios from "axios";
import { Toast } from 'vant';
import router from "../router";
import api_host from "../common/host";

axios.defaults.baseURL = api_host
axios.defaults.headers["Content-Type"] = "multipart/form-data";

//设置超时
axios.defaults.timeout = 15000;

axios.interceptors.request.use(
    config => {
        // 在发送请求之前做什么
        config.headers["INEEDTOKEN"] = localStorage.getItem("token") || "";
        config.headers["Accept-Language"] = localStorage.getItem("language") || "en-us";
        config.baseURL = axios.defaults.baseURL;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        if(response.data.code === 403) {
            Toast.fail("Login expired");
            localStorage.removeItem("token");
            router.push({"name": "login"});
        }
        if (response.status == 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        console.log(error);
    }
);
export default {
    /**
     * @param {String} url 
     * @param {Object} data 
     * @returns Promise
     */
    post(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'post',
                    url,
                    data: data,
                })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                });
        })
    },

    deletea(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'delete',
                url,
                data: data,
            })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            });
        })
    },

    get(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'get',
                    url,
                    params: data,
                })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    patch(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'patch',
                    url,
                    data: data,
                })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                });
        })
    },
};
